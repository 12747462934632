<template>
    <div class="children-attribute">
        <img src="../../../assets/imgs/mobile/children/img_shenz@2x.png" alt="兒童身高、體重、頭圍、胸圍介紹">
        <ul class="children-attribute-tabs">
            <router-link to="/children/height" class="children-attribute-tab">身高</router-link>
            <router-link to="/children/weight" class="children-attribute-tab">體重</router-link>
            <router-link to="/children/bmi" class="children-attribute-tab">BMI</router-link>
            <router-link to="/children/head_circ" class="children-attribute-tab current">頭圍</router-link>
            <router-link to="/children/chest_circ" class="children-attribute-tab">胸圍</router-link>
        </ul>
        <div class="children-attribute-content">
            <div class="children-attribute-item">
                <div style="width: 0px;height: 0px; position: relative; overflow: hidden;"><h1>小孩(兒童)頭圍正常值是多少？</h1></div>
                <p>頭圍是反映孩子腦發育的一個重要指標。頭圍在生後第一年增長最快。出生時頭圍平均34cm;1歲時平均46cm；第二年增加2cm，第三年增長1～2cm。3歲時頭圍平均為48cm，已與成人相差不很多了。由此可看出，腦發育主要在生後頭3年。正常小兒後囟門3個月閉合，前囟門1歲～1 歲半閉合。過遲閉合要考慮有無佝僂病的可能。有的孩子出生時囟門就較小，閉合也會早些。這與母親孕期營養狀況較好有關。因此要綜合看待這個問題，如果沒有超量服用魚肝油或超量服用及注射維生素D，一般問題不大。 需要注意的是，並非像人們所想像的那樣:孩子頭越大越聰明，聰明與否和頭圍大小並不成正比。孩子的頭圍在正常範圍內就可以了。頭圍過大則要考慮有無腦腫瘤、腦積水的可能。</p>
            </div>
        </div>

        
        <div class="chart">
            <div class="title">0～7歲男孩頭圍參照值(cm)</div>
            <div class="table">
                <div class="head">
                    <div class="column">年齡</div>
                    <div class="column">下等</div>
                    <div class="column">均值</div>
                    <div class="column">上等</div>
                </div>
                <div class="content">
                    <div class="row"><div class="column">初生</div><div class="column">31.9</div><div class="column">34.3±1.2</div><div class="column">36.7</div></div>
                    <div class="row"><div class="column">1月～</div><div class="column">35.5</div><div class="column">38.1±1.3</div><div class="column">40.7</div></div>
                    <div class="row"><div class="column">2月～</div><div class="column">37.1</div><div class="column">39.7±1.3</div><div class="column">42.3</div></div>
                    <div class="row"><div class="column">3月～</div><div class="column">38.4</div><div class="column">41±1.3</div><div class="column">43.6</div></div>
                    <div class="row"><div class="column">4月～</div><div class="column">39.7</div><div class="column">42.1±1.2</div><div class="column">44.5</div></div>
                    <div class="row"><div class="column">5月～</div><div class="column">40.6</div><div class="column">43±1.2</div><div class="column">45.4</div></div>
                    <div class="row"><div class="column">6月～</div><div class="column">41.5</div><div class="column">44.1±1.3</div><div class="column">46.7</div></div>
                    <div class="row"><div class="column">8月～</div><div class="column">42.5</div><div class="column">45.1±1.3</div><div class="column">47.7</div></div>
                    <div class="row"><div class="column">10月～</div><div class="column">43</div><div class="column">45.8±1.4</div><div class="column">48.6</div></div>
                    <div class="row"><div class="column">12月～</div><div class="column">43.9</div><div class="column">46.5±1.3</div><div class="column">49.1</div></div>
                    <div class="row"><div class="column">15月～</div><div class="column">44.5</div><div class="column">47.1±1.3</div><div class="column">49.7</div></div>
                    <div class="row"><div class="column">18月～</div><div class="column">45.2</div><div class="column">47.6±1.2</div><div class="column">50</div></div>
                    <div class="row"><div class="column">21月～</div><div class="column">45.5</div><div class="column">48.1±1.3</div><div class="column">50.7</div></div>
                    <div class="row"><div class="column">2.0歲～</div><div class="column">46</div><div class="column">48.4±1.2</div><div class="column">50.8</div></div>
                    <div class="row"><div class="column">2.5歲～</div><div class="column">46.6</div><div class="column">49±1.2</div><div class="column">51.4</div></div>
                    <div class="row"><div class="column">3.0歲～</div><div class="column">47</div><div class="column">49.4±1.2</div><div class="column">51.8</div></div>
                    <div class="row"><div class="column">3.5歲～</div><div class="column">47.4</div><div class="column">49.8±1.2</div><div class="column">52.2</div></div>
                    <div class="row"><div class="column">4.0歲～</div><div class="column">47.5</div><div class="column">50.1±1.3</div><div class="column">52.7</div></div>
                    <div class="row"><div class="column">4.5歲～</div><div class="column">47.8</div><div class="column">50.4±1.3</div><div class="column">53</div></div>
                    <div class="row"><div class="column">5.0歲～</div><div class="column">48.3</div><div class="column">50.7±1.2</div><div class="column">53.1</div></div>
                    <div class="row"><div class="column">5.5歲～</div><div class="column">48.5</div><div class="column">50.9±1.2</div><div class="column">53.3</div></div>
                    <div class="row"><div class="column">6～7歲</div><div class="column">48.9</div><div class="column">51.3±1.2</div><div class="column">53.7></div></div>
                </div>
            </div>
        </div>
        <div class="chart">
            <div class="title">0～7歲女孩頭圍參照值(cm)</div>
            <div class="table">
                <div class="head">
                    <div class="column">年齡</div>
                    <div class="column">下等</div>
                    <div class="column">均值</div>
                    <div class="column">上等</div>
                </div>
                <div class="content">
                    <div class="row"><div class="column">初生</div><div class="column">31.5</div><div class="column">33.9±1.2</div><div class="column">36.3</div></div>
                    <div class="row"><div class="column">1月～</div><div class="column">35</div><div class="column">37.4±1.2</div><div class="column">39.8</div></div>
                    <div class="row"><div class="column">2月～</div><div class="column">36.5</div><div class="column">38.9±1.2</div><div class="column">41.3</div></div>
                    <div class="row"><div class="column">3月～</div><div class="column">37.7</div><div class="column">40.1±1.2</div><div class="column">42.5</div></div>
                    <div class="row"><div class="column">4月～</div><div class="column">38.8</div><div class="column">41.2±1.2</div><div class="column">43.6</div></div>
                    <div class="row"><div class="column">5月～</div><div class="column">39.7</div><div class="column">42.1±1.2</div><div class="column">44.5</div></div>
                    <div class="row"><div class="column">6月～</div><div class="column">40.4</div><div class="column">43±1.3</div><div class="column">45.6</div></div>
                    <div class="row"><div class="column">8月～</div><div class="column">41.5</div><div class="column">44.1±1.3</div><div class="column">46.7</div></div>
                    <div class="row"><div class="column">10月～</div><div class="column">42.4</div><div class="column">44.8±1.2</div><div class="column">47.2</div></div>
                    <div class="row"><div class="column">12月～</div><div class="column">43</div><div class="column">45.4±1.2</div><div class="column">47.8</div></div>
                    <div class="row"><div class="column">15月～</div><div class="column">43.6</div><div class="column">46±1.2</div><div class="column">48.4</div></div>
                    <div class="row"><div class="column">18月～</div><div class="column">44.1</div><div class="column">46.5±1.2</div><div class="column">48.9</div></div>
                    <div class="row"><div class="column">21月～</div><div class="column">44.5</div><div class="column">46.9±1.2</div><div class="column">49.3</div></div>
                    <div class="row"><div class="column">2.0歲～</div><div class="column">45</div><div class="column">47.4±1.2</div><div class="column">49.8</div></div>
                    <div class="row"><div class="column">2.5歲～</div><div class="column">45.6</div><div class="column">48±1.2</div><div class="column">50.4</div></div>
                    <div class="row"><div class="column">3.0歲～</div><div class="column">46.2</div><div class="column">48.4±1.1</div><div class="column">50.6</div></div>
                    <div class="row"><div class="column">3.5歲～</div><div class="column">46.4</div><div class="column">48.8±1.2</div><div class="column">51.2</div></div>
                    <div class="row"><div class="column">4.0歲～</div><div class="column">46.7</div><div class="column">49.1±1.2</div><div class="column">51.5</div></div>
                    <div class="row"><div class="column">4.5歲～</div><div class="column">47</div><div class="column">49.4±1.2</div><div class="column">51.8</div></div>
                    <div class="row"><div class="column">5.0歲～</div><div class="column">47.3</div><div class="column">49.7±1.2</div><div class="column">52.1</div></div>
                    <div class="row"><div class="column">5.5歲～</div><div class="column">47.6</div><div class="column">50±1.2</div><div class="column">52.4</div></div>
                    <div class="row"><div class="column">6～7歲</div><div class="column">47.7</div><div class="column">50.3±1.3</div><div class="column">52.9</div></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
	export default {
        created: function(){
            setTimeout(() => {
                window.scrollTo(0, 860)    
            }, 0);
        }
    }
</script>

<style lang="scss" scoped>
    .children-attribute {
        margin: 0px 24px;
        position: relative;
        display: flex;
        flex-direction: column;
        background-color: #fff;
    }

    .children-attribute > img {
        display: block;
        width: 440px;
        height: 468px;
        margin: 40px 0px 0px 32px;
    }

    .children-attribute > .children-attribute-tabs {
        margin-top: 46px;
        height: 48pxx;
        display: block;
        display: flex;
        flex-direction: row;
        margin-left: 32px;
        justify-content: flex-start;
    }

    .children-attribute > .children-attribute-tabs > .children-attribute-tab {
        font-size: 34px;
        line-height: 33px;
        height: 48px;
        color: #231F20;
        font-weight: bold;
        display: inline-block;
        margin-right: 52px;
        cursor: pointer;
    }

    .children-attribute > .children-attribute-tabs > .children-attribute-tab.current {
        color: #36C4D0;
        position: relative;
    }

    .children-attribute > .children-attribute-tabs > .children-attribute-tab.current::after {
        content: '';
        position: absolute;
        left: 50%;
        bottom: 0px;
        height: 4px;
        width: 12px;
        background-color: #36C4D0;
        transform: translateX(-50%);
    }
    
    .children-attribute > .children-attribute-content {
        background-color: #fff;
        font-size: 27.54px;
        line-height: 41.32px;
        font-weight: 400;
        color: #636363;
        margin: 12px 32px;
    }

    
    .chart {
        margin-bottom: 20px;
    }

    .chart,
    .chart > .table {
        display: flex;
        flex-direction: column;
    }

    .chart > .title {
        height: 77px;
        line-height: 77px;
        color: #242020;
        font-size: 30px;
        font-weight: bold;
        position: relative;
        padding-left: 48px;
        margin-left: 32px;
        box-sizing: border-box;
    }

    .chart > .title::after {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 32px;
        height: 32px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url(../../../assets/imgs/pc/children/img_copy@2x.png);;
    }

    .chart > .table > .content > .row,
    .chart > .table > .head {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        background-color: #EEF9FA;
        height: 50px;
        align-items: center;
    }

    .chart > .table > .head {
        border-bottom: 1px solid #CEE5E7;
    }

    .chart:last-child {
        margin-bottom: 70px;
    }
    
    .chart:last-child > .table > .head {
        background-color: #FFE7E7;
        border-bottom: 1px solid #E8C5C5;
    }


    .chart > .table > .content > .row {
        background-color: #fff;
    }

    .chart > .table > .content > .row:nth-child(odd) {
        background-color: #F6F7F8;
    }

    .chart > .table > .head > .column,
    .chart > .table > .content > .row > .column {
        width: 100%;
        text-align: center;
        font-size: 30px;
        font-weight: 400;
        color: #646464;
    }

    .chart > .table > .head > .column {
        color: #242020;
        font-size: 28px;
        font-weight: bold;
    }
</style>
